<!-- 
    *******************************************************************
    * TEMPLATE 
    *******************************************************************
-->

<template>

    <v-container fill-height fluid >

        <v-layout justify-center align-center>
            
            <v-flex xs12 sm8 md6 lg5 xl4>
            
                <v-card dense elevation="10" > 
                        
                    <!--
                    <v-layout justify-center align-center>
                        <img 
                            div
                            height="160" 
                            class="ma-2" 
                            :src="require('@/assets/login.jpeg')" alt=""
                        >

                    </v-layout>
                    -->

                    <v-app-bar
                        color="light-blue darken-3"
                        dark 
                        flat>
                        <v-toolbar-title fat>
                            User and password
                        </v-toolbar-title>

                    </v-app-bar>

                    <v-card-text>
                        <v-text-field dense v-model="nombreUsuario" label ="Username" ></v-text-field>
                        <v-text-field 
                            class="my-pwd"
                            dense v-model="passwordUsuario" 
                            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                            label ="Password" 
                            @click:append="showPwd = !showPwd"
                            :type="showPwd ? 'text' : 'password'">
                        </v-text-field>
                    </v-card-text>

                    <v-card-text>
                        <v-layout dense justify-end>
                            <v-btn @click="validaUsuario" color="secondary">Login</v-btn>
                        </v-layout>
                        
                    </v-card-text>

            
                </v-card> 


                <v-snackbar
                v-model="muestraAlerta"
                :multi-line=false
                >

                    {{ textoAlerta }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="red"
                            text
                            v-bind="attrs"
                            @click="muestraAlerta = false" >
                            Close
                        </v-btn>
                    </template>

                </v-snackbar>


            </v-flex>
        </v-layout>

     </v-container>

</template>



<!-- 
    *******************************************************************
    * SCRIPT 
    *******************************************************************
-->

<script>


    import axios from "axios"

    export default {
        
        // ***********************************************************
        // data()
        // ***********************************************************

        data() {
            return {

                nombreUsuario       : "",
                passwordUsuario     : "",
                muestraAlerta       : false,
                textoAlerta         : "",
                showPwd             : false,
                urlTrialTour        : null,
            }
        },


        // ***********************************************************
        // created()
        // ***********************************************************

        created() {
            this.urlTrialTour = this.$store.state.base_url + "autentificaUsuarioPost/";
            this.$store.state.isHome = true;
            this.$store.state.isUserValidated = false;
        },

        // ***********************************************************
        // methods
        // ***********************************************************

        methods: {


            // -----------------------------------------------------------
            // validaUsuario()
            // Solicita la validación del usuario
            // -----------------------------------------------------------
            validaUsuario() {

                if ( this.nombreUsuario.trim() === "" || this.passwordUsuario.trim() === "" ) {
                    this.textoAlerta = "User or password is blank";
                    this.muestraAlerta = true;
                } else {

                const url = this.urlTrialTour

                var axiosConfig = {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Accept': '*',
                        }
                    };

                axios.post( 
                    url, 
                    {          
                        user: (this.nombreUsuario).trim(),
                        pwd : this.passwordUsuario.trim()
                    }, 

                    axiosConfig).then( response =>{ 

                        console.log("Respuesta de autentificacion recibida !!");
                        console.log(response.data);

                        if ( response.data.resultado == "OK") {

                                // redirigimos al Home
                                this.$store.state.isUserValidated = true;
                                this.$router.push( {name: 'ListaEnsayos'});

                        } else {

                                // mostramos aviso de que el usuario no está validado
                                this.textoAlerta = "User not validated !!";
                                this.muestraAlerta = true;
                        }

                }).catch(error => {

                    console.log('Sign up server error: ')
                    console.log(error);
                });
                }

            }
        }

    }

</script>


<!-- 
    *******************************************************************
    * STYLE 
    *******************************************************************
-->

<style  scoped>

.my-pwd input{
  text-transform: lowercase
}

</style>