import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import uploadPhoto from '../views/UploadFoto.vue'
import listaEnsayos from '../views/ListaEnsayos.vue'
import loginPage from '../views/Login.vue'
import distribucionEnsayo from '../views/Distribucion.vue'
import plotDetail from '../views/PlotDetail.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },

    {
        path: '/distribucion',
        name: 'Distribucion',
        component: distribucionEnsayo
    },


    {
        path: '/plotDetail',
        name: 'PlotDetail',
        component: plotDetail
    },

    {
        path: '/loginPage',
        name: 'LoginPage',
        component: loginPage
    },

    {
        path: '/listaEnsayos',
        name: 'ListaEnsayos',
        component: listaEnsayos
    },

    {
        path: '/uploadPhoto',
        name: 'UploadFoto',
        component: uploadPhoto
    },



    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router