<template>

<v-container justifi-center align-center>

   <v-card
        
        class="mx-auto my-12"
        max-width="374"
    >


        <v-img
        height="250"
        src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        ></v-img>

        <v-card-title>Envío de fotos a Trial Tour</v-card-title>

        <v-card-text>

            <v-text-field v-model="pkProtocolo" label="Protocol ID"></v-text-field> 
            <v-text-field v-model="pkEnsayo" label="Trial ID"></v-text-field>
            <v-text-field v-model="pkPlot" label="Plot"></v-text-field>
            <v-text-field v-model="fila" label="Application"></v-text-field>
            <v-text-field v-model="columna" label="Variation"></v-text-field>
            <v-text-field v-model="nivel" label="Level"></v-text-field>
            <v-text-field v-model="comentario" label="Photo level* "></v-text-field>







            <!--
            <input 
            style="display:none"
            id="fichero" 
            type="file"
            label="Selecciona o capture foto"
            ref="fileInput"
            @change="onFileSelected"/>
            -->


            <v-file-input 
            id="fichero" 
            v-model="file" 
            loader-height="false"
            loading="true"
            show-size
            label="Select Image File..." 
            accept="image/*"
            @change="onFileSelected"
            ></v-file-input>

            <br>
            <progress max="100" :value.prop="uploadPercentage"></progress>
            <br>

            <div id="preview">
                <v-img v-if="url" :src="url"   max-height="100" max-width="100" />
            </div>

    <v-snackbar
      v-model="snackbar"
    >
      Subida terminada !!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


<!--
            <v-btn
            color="primary"
            @click="$refs.fileInput.click()"
            >Select File</v-btn>           
 -->

            <v-textarea
            name="input-7-1"
            v-model="comentario" 
            filled
            label="Label*"
            auto-grow
            value="Comment the photo"
            ></v-textarea>

        </v-card-text>



        <v-card-actions>
        <v-btn
            color="deep-purple lighten-2"
            text
            @click="submitFile()"
        >
            Upload
        </v-btn>
        </v-card-actions>
    </v-card>

</v-container>



</template>

 

<script>

import axios from "axios"

export default {
  name: 'Home',

  data() {
      return {

          pkProtocolo: null,
          pkEnsayo: null,
          pkPlot : null,
          fila: null,
          columna:null,
          nivel: null,
          comentario: null,
          selectedFile : null,
          file: null,
          loading: true,
          uploadPercentage : null,
          snackbar: false,
          url:null
          
      }
  },

  components: {},


  methods: {


      onFileSelected() {
          console.log("Ha cambiado");
          if ( this.file != null) {
            this.url = URL.createObjectURL(this.file);
          } else {
            this.url = "";
          }
          // console.log(event);
          //this.selectedFile = event.target.files[0];

      },


        submitFile() {

      // Enviamos el POST al servidor con los datos introducidos

      const url = "http://192.168.1.66:8091/scriptcase/app/TrialTour/servicio_fotos/";

      var axiosConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*',
        },

        onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
            console.log( this.uploadPercentage );
            if ( this.uploadPercentage == 100 ) {
                this.snackbar = true;
            }

        }.bind(this)

      };

      const fd = new FormData();

      // fd.append('image', this.selectedFile, this.selectedFile.name);
      fd.append('image', this.file);
      fd.append('pkProtocolo', this.pkProtocolo);
      fd.append('pkEnsayo', this.pkEnsayo);
      fd.append('pkPlot', this.pkPlot);
      fd.append('fila', this.fila);
      fd.append('col', this.columna);
      fd.append('nivel', this.nivel);
      fd.append('comentario', this.comentario);

      axios.post( 
        url, 
        fd,
        axiosConfig).then( response =>{ 

          console.log("Respuesta recibida !!");
          console.log(response.data);

        }).catch(error => {
          console.log('Error al consultar envase: ')
          console.log(error);
        });
      


            
        }   
  }

}
</script>


<style scoped>
.botonera {
    display: flex;
    overflow-x: auto;
}
</style>