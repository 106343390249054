<!-- 
    *******************************************************************
    * TEMPLATE 
    *******************************************************************
-->

<template>

  <v-container justifi-center align-center pt-6>

    <!-- Boton de vuelta atrás -->
    <v-btn small class="primary" @click="goBack()">Go Back</v-btn>

    <div class="about pa-0">
      <h3>Plot Detail </h3>

      <p>
      Select the level and the image you want to change or add 
      or update the text corresponding to the selected image
      </p>

      <!-- Indicador de fila -->
      <v-chip class="info ma-1" label >
        {{idFila}}-{{getNombreColumna(idColumna)}}
      </v-chip>

      <!-- Indicador de columna -->
      <v-chip class="ma-1 warning" label>
        {{variante}}
      </v-chip>

      <!-- Indicador de variante -->
      <v-chip class="ma-1 pa-3 cyan dark" color="white" label>

        <v-icon medium color="white darken-2" >
          {{level_icon_name}}
        </v-icon>

        {{level_names[currentLevelIndex]}}

      </v-chip>

      <!-- Carousel para mostrar las imagenes -->
      <v-carousel
      height="400px"
      @change="carouselChange">
          <v-carousel-item 
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          ></v-carousel-item>
      </v-carousel>

      <!-- Boton para borrar -->

      <v-btn
        v-if="estadoActual == 1"
        small
        class="ma-1"
        depressed
        @click="onBtnDeleteFoto()"
        color="primary"
      >

          <v-icon
          small
          >
            mdi-trash-can
          </v-icon>

      </v-btn>


      <!-- Boton para añadir o cambiar la imagen -->
      <v-btn
        v-if="estadoActual == 1 || estadoActual == 2"
        @click="onBtnUpload()"
        small
        class="ma-1"
        depressed
        color="primary"
      >
      Add/Change image
      </v-btn>

      <!-- Boton para subir la imagen seleccionada -->
      <v-btn
        v-if="estadoActual == 4"
        small
        class="ma-1"
        depressed
        color="primary"
        @click="submitData('imagen')"
      >
      Upload image
      </v-btn>

      <!-- Preview de la imagen -->
      <div id="preview" v-if="estadoActual == 4">
          <v-img v-model="imagePreview" v-if="fileSrc" :src="fileSrc"   max-height="100" max-width="100" />
      </div>
      <br>

      <!-- Barra de progreso -->
      <progress v-if="estadoActual ==4 " max="100" :value.prop="uploadPercentage"></progress>


      <!-- Seleccion de fichero o imagen -->
      <v-file-input 
      v-if="estadoActual == 3 || estadoActual == 4 "
      id="fichero" 
      v-model="file" 
      loader-height="false"
      loading="true"
      show-size
      label="Select Image File..." 
      accept="image/*"
      @change="onFileSelected"
      ></v-file-input>

      <!-- Comentario de la imagen -->
      <v-textarea
            name="input-7-1"
            label="Comment"
            filled
            auto-grow
            v-model="comentario"
            :value="comentario"
            hint="Hint text"
      ></v-textarea>

      <!-- Boton para actualizar comentario -->
      <v-btn
        small
        class="ma-2"
        depressed
        color="primary"
        @click="submitData('comentario')"
      >
        Update comment
      </v-btn>

    </div> 

    <!-- Mensaje emergente -->

    <v-snackbar v-model="snackbar">
      Subida terminada !!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialogo modal para solicitar confirmación de borrado -->

    <v-dialog v-model="confirmacionBorrado" persistent max-width="290" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>

      </template>
      <v-card>
        <v-card-title class="headline justify-center" >
          Are you sure ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="warning" text @click="confirmacionBorrado = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small class="success" text @click="dialog = false">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>


</template>






<!-- 
    *******************************************************************
    * SCRIPT 
    *******************************************************************
-->

<script>

import axios from "axios"


  export default {


    // ***********************************************************
    // data()
    // ***********************************************************

    data () {
      return {

        idPlot          : null,           // Id del plot que se recupera del servicio
        idNivel         : null,           // NIvel actual que se está mostrando (1-3)        
        idFila          : null,
        idColumna       : null,
        urlTrialTour    : null,           // URL para obtener la info
      
        comentario        : null,         // Comentario actual
        currentLevel      : null,         // Texto del nivel que se muestra
        currentLevelIndex : null,         // NIvel que se está mostrando (1-3)
        confirmacionBorrado : false,      // Controla la aparicion del dialogo de confirmacion

        selectedFile      : null,
        file              : null,
        imagePreview      : null,
        loading           : true,
        uploadPercentage  : null,
        snackbar          : false,
        estadoActual      : null,
        variante          : null,
        level_icon_names  :               // NOmbre de los iconos para representar cada nivel
            ['','mdi-border-bottom-variant','mdi-source-branch','mdi-flower'],
        level_names       :               // NOmbre de cada nivel
            ['','Plot view','Plant view','Detailed view'],
        level_icon_name   : null,
        fileSrc           : null,
        fileSrcResized    : null,

        // Almacena las fotos y comentarios de cada nivel del plot
        items: [
          {
            src: null,
            comment : null
          },
          {
            src: null,
            comment : null
          },
          {
            src: null,
            comment : null
          }
        ],
      }
    },


    created() {


        console.log("Created para obtener información del plot ... ");
        this.urlTrialTour = this.$store.state.base_url + "getDataPlotPost/",

        // Obtenemos los valores que llegan por parámetro
        this.idEnsayo =  this.$route.params.idEnsayo; 
        this.idPlot =  this.$route.params.idPlot;  
        this.idFila = this.$route.params.idFila;  
        this.idColumna = this.$route.params.idColumna;  
        this.variante = this.$route.params.variante;  
        this.idNivel = 1;

        // this.idPlot = 22;

        this.comentario = "";
        this.currentLevel = "Level 1";
        this.level_icon_name = this.level_icon_names[1];
        this.estadoActual = (this.idPlot == "") ? 2:1;
        
        // Obtenemos la información del plot
        this.obtieneInfoPlot( this.idPlot) ;

        
    },


    // ***********************************************************
    // methods
    // ***********************************************************

    methods: {


      // -----------------------------------------------------------
      // goBack()
      // Vuelve a la página anterior
      // -----------------------------------------------------------

      goBack() {

          this.$router.push( { 
            name: 'Distribucion', 
            params: { 
              idEnsayo: this.idEnsayo 
              } 
            } );
      },

      // -----------------------------------------------------------
      // getNombreColumna()
      // Devuelve la letra correspondiente a un indice de columna
      // -----------------------------------------------------------

      getNombreColumna( idCol ){
        let colnames = "-ABCDEFGHIJKLMNOP";
        return (colnames[idCol]);
      },


      // -----------------------------------------------------------
      // obtieneInfoPlot()
      // Obtiene información de una parcela determinada pk = IdPlot
      // -----------------------------------------------------------

      obtieneInfoPlot( idPlot ) {

        console.log("Obteniendo información del plot ......");

        const urlGetInfo = this.$store.state.base_url + "getDataPlotPost/";
        
        var axiosConfig = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                          // 'Content-Type': 'text/json',
                          // "Access-Control-Allow-Origin": "*",
                        'Accept': '*',
                    }
                };
          axios.post( 
              urlGetInfo, 
              {
                idPlot : idPlot,
                idEnsayo : 0,
                idFila : 0,
                idColumna : 0
              },
              axiosConfig).then( response =>{ 

                console.log("Respuesta recibida !!!");

                // Guardamos la informacion del plot
                this.infoPlot = response.data.dataplot;

                // Guardamos los comentarios obtenidos en el plot
                this.items[0].comment = this.infoPlot.comentario_n1;
                this.items[1].comment = this.infoPlot.comentario_n2;
                this.items[2].comment = this.infoPlot.comentario_n3;

                // Guardamos las imagenes obtenidas del plot
                this.items[0].src = this.infoPlot.file_img_n1;
                this.items[1].src = this.infoPlot.file_img_n2;
                this.items[2].src = this.infoPlot.file_img_n3;

                console.log(this.items[0].src);
                console.log("examinando blancos");
                console.log( this.infoPlot);
                

                // Si alguna imagen viene a blanco, su imagen será no_image.jpg
                for ( var i=0; i<3; i++) {
                  if ( this.items[i].src == "" ) {
                      console.log("blanco encontrado en " + i);
                      this.items[i].src=require('@/assets/no_image.jpg');
                  }
                }
                
                // Inicializamos el primer comentario mostrado con el comentario
                // de nivel 1 del plot ontenido

                this.comentario = this.infoPlot.comentario_n1;

              }).catch(error => {

                console.log('Sign up server error: ')
                console.log(error);

              });
      },



      // -----------------------------------------------------------
      // carouselChange()
      // Se invoca cada vez que se cambia de nivel, en cuyo caso se
      // actualizan la foto, el comentaio y los botones
      // -----------------------------------------------------------

      carouselChange( event ) {
          
          console.log("cambio de nivel a " + (event+1));

          // Almacenamos el nivel que se está mostrando
          this.currentLevelIndex = (event+1);


          if ( this.infoPlot != null ) {

            this.estadoActual = this.obtieneEstado(); // ????????????
            
            // Actualizamos la etiqueta del nivel
            this.currentLevel = "Level " + (event+1);

            // Mostramos el comentario correspondiente al nivel seleccionado
            if ( event == 0) {
                console.log("uno");
                this.comentario = this.infoPlot.comentario_n1;
            } else if ( event == 1) {
                console.log("dos");
                this.comentario = this.infoPlot.comentario_n2;
            } else if ( event == 2) {
                console.log("tres");
                this.comentario = this.infoPlot.comentario_n3;
            }

            // Actualizasmos el indice del nivel (1-3)
            this.idNivel = event+1;
            // Obtenemos el nombre el icono que representa al nivel
            this.level_icon_name = this.level_icon_names[this.idNivel];


          } 
          
          

      },

      // ??????????????????
      onBtnUpload() {
        this.estadoActual = 3;
      },

      // ???????????????????
      obtieneEstado() {

        var estado = null;
        
        switch ( this.currentLevelIndex ){
          case 1:
            if ( this.infoPlot.file_img_n1 == "" ) { estado = 2; } else { estado = 1;}
            break;
          case 2:
            if ( this.infoPlot.file_img_n2 == "" ) { estado = 2; } else { estado = 1;}
            break;
          case 3:
            if ( this.infoPlot.file_img_n3 == "" ) { estado = 2; } else { estado = 1;}
            break;
        }

        console.log( "obtenido estado " + estado );

        return estado;

      },

      
    // -----------------------------------------------------------
    // onFileSelected()
    // Se ha seleccionado un fichero para subir
    // -----------------------------------------------------------

    onFileSelected() {

        if ( this.file != null) {

          // ?????
          this.estadoActual = 4;

          // Almacenamos la imagen
          this.fileSrc = URL.createObjectURL(this.file);

        } else {
          // ???????????????
          this.estadoActual = 3;

        }

    },

      // -----------------------------------------------------------
      // onBtnDeleteFoto()
      // Se ha pulsado el boton para eliminar la foto. Se envia al 
      // servicio la petición de eliminación
      // -----------------------------------------------------------


      onBtnDeleteFoto() {
          this.confirmacionBorrado = true;
      },

      // -----------------------------------------------------------
      // onBtnDeleteFoto()
      // Se ha pulsado el boton para eliminar la foto. Se envia al 
      // servicio la petición de eliminación
      // -----------------------------------------------------------

      borrarFoto() {

        this.confirmacionBorrado = false;

        // Enviamos el POST al servidor con los datos introducidos

        const urlDelete = this.$store.state.base_url + "setDataPlotPost/";

        var axiosConfig = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*',
          }

        };

        // Creamos el formData para la solicitud
        const fd = new FormData();
        var operacion = "delete_foto";
        fd.append('operacion', operacion);
        fd.append('idEnsayo', this.idEnsayo);
        fd.append('idPlot', this.idPlot);
        fd.append('idNivel', this.idNivel);

        // Enviamos la solicitud de borrado al servicio
        axios.post( 
          urlDelete, 
          fd,
          axiosConfig).then( response =>{ 

            console.log("Respuesta recibida !!");
            console.log(response.data);
            // Mostramos mensaje
            this.snackbar = true;

          }).catch(error => {
            console.log('Error al borrar imagen: ')
            console.log(error);
          });

      },


    // -----------------------------------------------------------
    // submitData()
    // Solicitamos al servicio una operación de inserción o de
    // actualización de foto o comentario
    // -----------------------------------------------------------

    submitData( tipo ) {

      const urlSave = this.$store.state.base_url + "setDataPlotPost/";

      var axiosConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*',
        },

          onUploadProgress: function( progressEvent ) {
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
              console.log( this.uploadPercentage );
              //if ( this.uploadPercentage == 100 ) {
              //    this.snackbar = true;
              //}

          }.bind(this)

      };

      var operacion = null;

      console.log("Tipo " + tipo +", PkPlot " + this.idPlot);

      // Establecemos la opeación que queremos realizar
      if ( tipo == 'imagen' && ( this.idPlot != "" && this.idPlot != null ) ) {
        operacion = "update_foto";
      } else if (tipo == 'imagen' && ( this.idPlot == "" || this.idPlot == null )) {
        operacion = "insert_foto";
      } else if ( tipo == 'comentario' && ( this.idPlot != "" && this.idPlot != null ) ) {
        operacion = "update_comentario";
      } else if (tipo == 'comentario' && ( this.idPlot == "" || this.idPlot == null )) {
        operacion = "insert_comentario";
      }

      console.log("Operacion " + operacion);
      console.log("Current level index " + this.currentLevelIndex);

      // Creamos el formData para solicitarlo al servicio
      const fd = new FormData();

      fd.append('operacion', operacion);
      fd.append('image', this.file);
      fd.append('idEnsayo', this.idEnsayo);
      fd.append('idPlot', this.idPlot);
      fd.append('idFila', this.idFila);
      fd.append('idColumna', this.idColumna);
      fd.append('idNivel', this.idNivel);
      fd.append('comentario', this.comentario);
      fd.append('variante', this.variante);
      fd.append('filename', "");
      fd.append('oldfilename', (this.items[this.currentLevelIndex-1]).src);

      // Enviamos la solicitud al servicio

      axios.post( 
        urlSave, 
        fd,
        axiosConfig).then( response =>{ 

          console.log("Respuesta recibida !!");
          console.log(response.data);
           this.snackbar = true;

        }).catch(error => {
          console.log('Error al consultar envase: ')
          console.log(error);
        });
                  
      }   

    }

  }
</script>

<!-- 
    *******************************************************************
    * STYLE 
    *******************************************************************
-->

<style scoped>

.fila {
    color: blue;
}

</style>