<template>

  <v-app id="inspire">

  <v-navigation-drawer 
      v-model="drawer"
      app
  >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Trial Tour
          </v-list-item-title>
          <v-list-item-subtitle>
            Task
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>







<v-app-bar
      app
      color="#fcb69f"
      dark
      
      src="https://picsum.photos/1920/1080?random"
      
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,1.0)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>



      <v-app-bar-title>TrialTour</v-app-bar-title>

      <v-spacer></v-spacer>


    </v-app-bar>



    

    <v-main>
      <!-- If using vue-router -->
      <router-view></router-view>
      
    </v-main>
  </v-app>
</template>

<script>

// import UploadFoto from './views/UploadFoto';


import sys_conf from "./config/config.json"


export default {
  name: 'App',

  components: {

    // UploadFoto,
  },

  created() {
    console.log("Aplication created ... reading config file ...");
    console.log("basse url: " + sys_conf.base_url);
    // Almacenamos la configracion en el store
    this.$store.state.base_url = sys_conf.base_url;


    


  },

  data() {
    return {
        drawer: null,
        items: [
            { title: 'Login', icon: 'mdi-clipboard-list' , to:'/loginPage'},
            { title: 'Trials', icon: 'mdi-clipboard-list' , to:'/listaEnsayos'},
            { title: 'About', icon: 'mdi-help-box', to:'/about' },

            { title: 'Visor 360', icon: 'mdi-help-box', to:'/visor360' },

          ]
    }
  }

};
</script>
 