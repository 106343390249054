
<!-- 
    *******************************************************************
    * TEMPLATE 
    *******************************************************************
--> 

<template>

  <v-container justifi-center align-center pt-6>

    <v-btn small class="primary" @click="goBack()">Go Back</v-btn>

    <h2>Trial name</h2>
    <p class="infoEnsayo">
      Apple at Abla ( Almería )
    </p>
    <h3>Trial Distribution</h3>

    <p v-if="infoDistribucion">
      Select a plot for add comments and photos or edit registered data
    </p>
    <p v-else>
      Not data for this trial
    </p>

    <!-- Distribucion -->
    <v-container class="botonera">

      <table>

        <template v-for="(fila, iFila) in infoDistribucion">
        <tr :key="iFila">
            <template v-for="(columna, iCol) in fila">

            <!-- Celda que contiene el boton que representa cada parcela -->
            <td :key="iCol"> 
              <v-btn 
                dark 
                small
                @click="seleccionPlot(iFila, iCol, columna.P)"  
                :color="getEstiloPlot(columna.P)" >{{columna.V}}
              </v-btn><br>

              <center><span class="labelPlot">{{columna.F}}-{{columna.C}}</span></center></td>

            </template>
        </tr>
        </template>

      </table>
    </v-container>

  </v-container>

</template>

 
<!-- 
    *******************************************************************
    * SCRIPT 
    *******************************************************************
-->

<script>

  import axios from "axios"

  export default {

    // ***********************************************************
    // data()
    // ***********************************************************

    data() {
        return {
          idEnsayo          : null,
          urlTrialTour      : null,
          infoDistribucion  : null,
          nombreCols        : "ABCDEFGHIJKLMN"
        }
    },

    // ***********************************************************
    // created()
    // ***********************************************************

    created() {

        // Establecemos la URL de donde se obtengdrán los datos
        this.urlTrialTour = this.$store.state.base_url + "getPlotsPost/";

        // Obtenemos los valores que llegan por parámetro
        this.idEnsayo =  this.$route.params.idEnsayo;  
        
        // Obtenemos la distribución del ensayo
        this.obtieneInfoDistribucion( this.idEnsayo ) ;
        
    },

    // ***********************************************************
    // methods
    // ***********************************************************

    methods: {

        // -----------------------------------------------------------
        // goBack()
        // Vuelve a la página anterior
        // -----------------------------------------------------------

        goBack() {
            this.$router.push( {name: 'ListaEnsayos'} );
        },


        // -----------------------------------------------------------
        // getEstiloPlot()
        // Devuelve el estilo que se aplicará al boton que representa
        // el plot
        // -----------------------------------------------------------

        getEstiloPlot( plotId ) {
          
          if ( plotId == "" ) {
           return "blue-grey";
          } else {
            return "success";
          }
        },


        // -----------------------------------------------------------
        // seleccionPlot()
        // Se invoca cuando pulsa un boton que representa un plot
        // -----------------------------------------------------------

        seleccionPlot( fila, col, pk  ) {
          
          // Obtenemos la cariante
          let variante = this.infoDistribucion[fila][col].V;


          // Valos a la pantalla de detalle del plot
          this.$router.push( {name: 'PlotDetail', 
            params: { 
              idEnsayo : this.idEnsayo,
              idPlot: pk,
              idFila: fila+1,
              idColumna: col+1,
              variante : variante
             } } );
         
        },


        // -----------------------------------------------------------
        // obtieneInfoDistribucion()
        // Obtiene la distribución del ensayo consultando al servicio
        // -----------------------------------------------------------

        obtieneInfoDistribucion() {

          const url = this.urlTrialTour

          var axiosConfig = {
                      headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                            // 'Content-Type': 'text/json',
                            // "Access-Control-Allow-Origin": "*",
                          'Accept': '*',
                      }
                  };
              axios.post( 
                  url, 
                  {
                    pk_ensayo : this.idEnsayo
                  },
                  axiosConfig).then( response =>{ 

                     
                      console.log("Distribución obtenida !!!");
                      this.infoDistribucion = response.data.distribucion;
                      
              }).catch(error => {

                  console.log('Sign up server error: ')
                  console.log(error);

              });
        }

    }


  }

</script>


<!-- 
    *******************************************************************
    * STYLE 
    *******************************************************************
-->

<style scoped>
.botonera {
    display: flex;
    overflow-x: auto;
    padding:0 ;
  
}

.labelPlot {
  font-size: 0.8rem;
  color : gray;
  text-align: center;

}

.infoEnsayo{
  color: gray;
  font-size: 0.8rem;
}

</style>