
<!-- 
    *******************************************************************
    * TEMPLATE 
    *******************************************************************
-->

<template>

    <v-container class="pt-6 pl-p pr-0">

      <!-- Mensaje para mostrar alerta de Ensayos no encontrados -->
      <v-alert
        v-if="mostrarAlerta" 
        class="ma-2"
        type="info"
        dark
        outlined>
        Not items found.
      </v-alert>

      <h1>Trial List</h1>

      <!-- Lista de ensayos --> 

      <v-list dense class="pa-0 pb-0 ma-0">

        <template v-for="(item, index) in items">

          <v-subheader
            v-if="item.header"
            :key="item.header"
            v-text="item.header"
          ></v-subheader>

          <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          ></v-divider>


          <v-list-item
            v-else
            :key="item.title"
            @click="goToDetalleEnsayo( item.pk )"
          >
            <v-list-item-avatar>
              <v-img :src="require('../assets/ensayo.jpg')"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.titulo+' '+item.ano"></v-list-item-title>
              <v-list-item-subtitle v-html="item.protocolo+' over ' + item.cultivo"></v-list-item-subtitle>
              <v-list-item-subtitle v-html="item.municipio+' ('+item.provincia+')'"></v-list-item-subtitle>
              <v-list-item-subtitle v-html="item.estado"></v-list-item-subtitle>

            </v-list-item-content>
          </v-list-item>

          <v-divider :key="index"></v-divider>
          
        </template>
      
      </v-list>

    </v-container>

</template>



<!-- 
    *******************************************************************
    * SCRIPT 
    *******************************************************************
-->

<script>

import axios from "axios"


  export default {

    components : {
     
    },

    // ***********************************************************
    // data()
    // ***********************************************************

    data: () => ({

        urlTrialTour      : null,         // url de acceso a los datos
        mostrarAlerta     : false,
        items             : [],           // lista de elementos a mostrar
        numItems          : 0,            // numero de elementos a mostrar

    }),


    // **************************************************************
    // created()
    // **************************************************************

    created() {

        // Establecemos la url para obtener la lista de ensayos
        this.urlTrialTour = this.$store.state.base_url + "getListaEnsayosPost/";

        // Obtenemos la lisya de productos
        this.obtieneListadoEnsayos() ;

    },


    // **************************************************************
    // methods
    // **************************************************************

    methods: {


        // -----------------------------------------------------------
        // goToDetalleEnsayo()
        // Navega a la pantalla que muestra la distribución del ensayo
        // -----------------------------------------------------------

        goToDetalleEnsayo( idEnsayo ) {

            this.$router.push( {name: 'Distribucion', params: { idEnsayo: idEnsayo } } );
        },


        /// -----------------------------------------------------------
        // obtieneListadoEnsayos()
        // Consulta la lista de ensayos registrados y actualiza la
        // lista de itemas que van a se mostrados
        // -----------------------------------------------------------

        obtieneListadoEnsayos() {
        
          const url = this.urlTrialTour
          console.log("Solicitando la lista de ensayos a " + url );


          var axiosConfig = {
                      headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                            // 'Content-Type': 'text/json',
                            // "Access-Control-Allow-Origin": "*",
                          'Accept': '*',
                      }
                  };
              axios.post( 
                  url, 
                  null, 
                  axiosConfig).then( response =>{ 

                      console.log("Respuesta recibida !!");
                      // console.log(response.data);

                      // Almacenamos los items recibidos en la consulta
                      this.items = response.data.items;
                      this.numItems = response.data.num_items;

                      // Si el numero de items es cero , mostraos alerta "No hay resultados"
                      this.mostrarAlerta = (this.numItems === 0);

              }).catch(error => {

                  console.log('Sign up server error: ')
                  console.log(error);

              });
      },

    }

  }
</script>





<!-- 
    *******************************************************************
    * STYLE 
    *******************************************************************
-->


<style scoped>

.NumIdentificacion {
    font-size : 1.1rem;
    font-weight : bold;
    
}

.alias {
    font-size : 0.9rem;
}

</style>