import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

        // base_url : "http://192.168.1.66:8091/scriptcase/app/TrialTour/getListaEnsayosPost/",
        base_url: "https://trialtour.labsosiris.com/"

    },
    mutations: {},
    actions: {},
    modules: {}
})